<style>
/* Exemplo de ajuste do título do blog em diferentes dispositivos */

/* Dispositivos móveis */
@media only screen and (max-width: 600px) {
  #titulo {
    font-size: 24px;
  }
}

/* Tablets */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  #titulo {
    font-size: 40px;
  }
}

/* Desktops */
@media only screen and (min-width: 1025px) {
  #titulo {
    font-size: 48px;
  }
}
</style>
<template>
    <div>
        <section class="ftco-section">
            <div class="container">
                <div class="row d-flex ">
                    <div class="col-md-12 wrap-about">
                        <h5 class="mb-0" style="font-size: 18px; font-weight: bold;">
                            #BLOG - {{ new Date(this.model.createdAt).toLocaleDateString() }} {{ new Date(this.model.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }}
                        </h5>
                        <h6 v-if="this.alteracao.codigo">
                            Postagem atualizada em: {{ new Date(this.alteracao.createdAt).toLocaleDateString() }} {{ new Date(this.alteracao.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }}
                        </h6>
                        <div v-if="this.model.fotoUrl" style="margin: 1px;">
                            <img :src="`${this.model.fotoUrl}`" style="width: 100%; border-radius: 10px;"/>
                        </div>
                        <h2 id="titulo">{{ this.model.titulo }}</h2>
                        <div v-if="this.codigo" v-html="ajustarConteudo(this.model.conteudo)"></div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from "../../axios/index";
export default {
      props: {
        codigo: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            model: {
                titulo: '',
                conteudo: '',
                empresaId: null,
                permissoesPadrao: [],
                necessarioAtualizar: false,
                dataHoraAtualizacao: ''
            },
            alteracao: {
                codigo: '',
            }
        };
    },
    async created() {    
        await this.pegarPost()
        window.scrollTo({
            top: 0,
        });
    },
    methods: {
        async pegarPost() {
            await axios.get(`/blog/bySite/${this.codigo}`)
                .then(async (response) => {
                    if (response.status === 200) {
                        this.model = response.data
                        this.verificarAlteracao()
                    } 
                }).catch(error => {
                    try {
                        this.$q.notify({
                            message: error.response.data.error,
                            color: 'red'
                        })
                    } catch (errr) {
                        this.$q.notify({
                            message: 'Falha na conexão com a nuvem.',
                            color: 'red'
                        })
                    }
                }).finally(() => {
                })
        },
        async verificarAlteracao() {
            await axios.get(`/blogAlteracao/bySite/${this.codigo}`)
                .then(async (response) => {
                    if (response.status === 200) {
                        this.alteracao = response.data
                    }
                }).catch(error => {
                    try {
                        this.$q.notify({
                            message: error.response.data.error,
                            color: 'red'
                        })
                    } catch (errr) {
                        this.$q.notify({
                            message: 'Falha na conexão com a nuvem.',
                            color: 'red'
                        })
                    }
                }).finally(() => {

                })
        },
        ajustarConteudo(conteudo) {
            return conteudo.replace(/<img\b[^>]*>/gi, "");
        }
    }
}
</script>