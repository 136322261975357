<style>
   .text-justify {
      text-align: justify;
   }
</style>
<template>
   <div>
      <section class="home-slider owl-carousel" id="inicio">
         <div class="slider-item" style="background-image:url(/images/PDV/Banner_1.png)">
            <div class="overlay"></div>
            <div class="container">
               <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                  <div class="col-md-7 ftco-animate">
                     <span class="subheading">Simples, rápido e inteligente.</span>
                     <h1 class="mb-4">Para quem é exigente na hora de Vender.</h1>
                     <p><a href="/#contato" class="btn btn-primary px-4 py-3 mt-3">Pedir orçamento</a></p>
                  </div>
               </div>
            </div>
         </div>
         <div class="slider-item" style="background-image:url(/images/PDV/Banner_2.png)">
            <div class="overlay"></div>
            <div class="container">
               <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                  <div class="col-md-7 ftco-animate">
                     <span class="subheading">Notas fiscais, pedidos e comandas!</span>
                     <h1 class="mb-4">Tenha tudo em um só lugar!</h1>
                     <p><a href="/#contato" class="btn btn-primary px-4 py-3 mt-3">Pedir orçamento</a></p>
                  </div>
               </div>
            </div>
         </div>
         <div class="slider-item" style="background-image:url(/images/PDV/Banner_3.png)">
            <div class="overlay"></div>
            <div class="container">
               <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                  <div class="col-md-7 ftco-animate">
                     <span class="subheading">Trabalhe até sem conexão com a internet.</span>
                     <h1 class="mb-4">Caiu a internet? Trabalhe em Contingência!</h1>
                     <p><a href="/#contato" class="btn btn-primary px-4 py-3 mt-3">Pedir orçamento</a></p>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="hero-wrap hero-wrap-2" style="background-image:url(/images/Fundos/4.png)">
         <div class="overlay"></div>
         <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-center">
               <div class="col-md-9 ftco-animate text-center">
                  <h1 class="mb-2 bread">Guappo PDV</h1>
               </div>
            </div>
         </div>
      </section>
      <section class="ftco-section ftco-no-pb mb-5">
         <div class="container">
            <div class="row d-flex">
               <div class="col-md-12 wrap-about pr-md-4 ftco-animate">
                  <h2 class="mb-4 text-center">Conheça algumas funcionalidades</h2>
                  <p class="text-center">Conosco a simplicidade e tecnologia caminham lado a lado, proporcionando a você uma melhor experiência!</p>
                  <div class="row mt-5">
                     <div class="col-lg-12">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">NFC-e (Nota Fiscal do Consumidor Eletrônica)</h3>
                              <p>Com a nossa frente de caixa vender ficou mais fácil e ágil, o verdadeiro papa filas, intuitivo, interativo, simples mas porém robusto e integrado com o Contas a Receber, controla a movimentação financeira dos clientes, trabalha online e offline, permite acréscimos e descontos, controle de convênios, controle de promoções, integrado com vários modelos de balança, envio automático das NFC-e em contingencia, envio de XML para o contador, envio da DANFE para os clientes via e-mail etc.</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">TEF (Transferência Eletrônica de Fundos)</h3>
                              <p>O dinheiro está perdendo espaço cada vez mais para os cartões de crédito e débito, talvez até mesmo por segurança, pelas formas de parcelamentos, benefícios das milhas e promoções, são fortes atrativos para a sua utilização, o TEF faz a integração entre as vendas de cartões e o nosso sistema de automação comercial.</p>
                           </div>
                        </div>
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Fluxo de caixa</h3>
                              <p>Controle o seu financeiro, tenha uma visão mais ampla do seu negócio, acompanhe a saúde financeira, controle as suas entradas e saídas etc.</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Pré-venda/Orçamentos</h3>
                              <p>Com o modulo de pré-venda/orçamentos podemos obter mais agilidade e facilidade, permite pesquisar por orçamentos realizados durante um período, permite transformar em venda (NFC-e e NF-e), envio por e-mail, imprime em vários formatos e impressoras etc.</p>
                           </div>
                        </div>
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Comanda</h3>
                              <p>É um modulo desenvolvido para gerar ganho de produtividade no atendimento pelo garçom, redução de digitação, controle de mesas, controle de balanças, cartão individuais de consumo, controle de comissionamento, rateio de despesas, controle de comandas ativas e inativas, disponibilização dos pedidos na cozinha etc.</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Personalização</h3>
                              <p>
                                 Personalize todos seus PDV's (pontos de venda) com as cores e logo do seu negócio.
                              </p>
                           </div>
                        </div>
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Controle de Usuários</h3>
                              <p>Dê permissões específicas para cada usuário, e controle as vendas por usuários.</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Clientes</h3>
                              <p>No próprio PDV você consegue vincular um cliente a uma venda, controlar o crédito do cliente e ainda visualizar pendências, carnês e muito mais.</p>
                           </div>
                        </div>
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Produtos</h3>
                              <p>A nossa pesquisa inteligente permite que você encontre os produtos rapidamente por categoria, nome, descrição, código de barras e corredor.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      
      <section style="width: 99vw">
        <vueper-slides
          fade
          :visible-slides="1"
          slide-multiple
          :autoplay="true"
          :duration="3500"
          :arrows-outside="false"
        >
            <vueper-slide
              v-for="(slide, i) in slides" 
              :key="i" 
              :image="slide.image"
            />
        </vueper-slides>
      </section>
   </div>
</template>


<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
  components: { VueperSlides, VueperSlide },    
  data () {
    return {
      slides: [
        {
          image: require('../../../public/images/PDV/1.png')
        },
        {
          image: require('../../../public/images/PDV/2.png')
        },
        {
          image: require('../../../public/images/PDV/3.png')
        }
      ]
    }
  }
}
</script>