<template>
   <div>
      <section class="ftco-section">
         <div class="container">
            <div class="row justify-content-center mb-5 pb-2">
               <div class="col-md-8 text-center heading-section ftco-animate">
                  <h2 class="mb-4">Downloads</h2>
               </div>
            </div>
            <div class="row no-gutters">
               <div class="col-lg-6 d-flex" style="cursor: pointer;" @click="download('https://tiapps.com.br/Arquivos/TabelaIBPT.csv')">
                  <div class="services-2 noborder-left text-center ftco-animate">
                     <div class="text media-body">
                        <h3>Tabela IBPT</h3>
                        <p>Tabela contendo carga tributária de NCM.</p>
                     </div>
                  </div>
               </div>
               <div class="col-lg-6 d-flex" style="cursor: pointer;" @click="download('https://tiapps.com.br/Arquivos/Remoto.zip')">
                  <div class="services-2 text-center ftco-animate">
                     <div class="text media-body">
                        <h3>Suporte Remoto</h3>
                        <p>Aplicativo com diretriz de acesso direto aos nossos técnicos.</p>
                     </div>
                  </div>
               </div>
               <div class="col-lg-6 d-flex" style="cursor: pointer;" @click="download('https://tiapps.com.br/Arquivos/DotNetFx45.zip')">
                  <div class="services-2 text-center ftco-animate">
                     <div class="text media-body">
                        <h3>Microsoft .NET Framework 4.5.1</h3>
                        <p>Pacote de instalação Framework 4.5.1 compátivel com Windows 7 SP1 em diante.</p>
                     </div>
                  </div>
               </div>
               <div class="col-lg-6 d-flex" style="cursor: pointer;" @click="download('https://tiapps.com.br/Arquivos/dotNetFx40.zip')">
                  <div class="services-2 noborder-left noborder-bottom text-center ftco-animate">
                     <div class="text media-body">
                        <h3>Microsoft .NET Framework 4</h3>
                        <p>Pacote de instalação Framework 4 compátivel com Windows XP.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>

<script>
export default {
    methods: {
        download (link) {
            window.open(link, '_blank')
        }
    }
}
</script>