<style>
   .text-justify {
      text-align: justify;
   }
</style>
<template>
   <div>
      <section class="home-slider owl-carousel" id="inicio">
         <div class="slider-item" style="background-image:url(/images/APPs/Banner_1.png)">
            <div class="overlay"></div>
            <div class="container">
               <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                  <div class="col-md-7 ftco-animate">
                     <span class="subheading">Comanda/Pedido.</span>
                     <h1 class="mb-4">Agilize seu negócio com o Guappo Comanda/Pedido!</h1>
                     <p><a href="/#contato" class="btn btn-primary px-4 py-3 mt-3">Pedir orçamento</a></p>
                  </div>
               </div>
            </div>
         </div>
         <div class="slider-item" style="background-image:url(/images/APPs/Banner_2.png)">
            <div class="overlay"></div>
            <div class="container">
               <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                  <div class="col-md-7 ftco-animate">
                     <span class="subheading">Controle seu saldo!</span>
                     <h1 class="mb-4">Com o Guappo Contagem você diminui custos.</h1>
                     <p><a href="/#contato" class="btn btn-primary px-4 py-3 mt-3">Pedir orçamento</a></p>
                  </div>
               </div>
            </div>
         </div>
         <div class="slider-item" style="background-image:url(/images/APPs/Banner_3.png)">
            <div class="overlay"></div>
            <div class="container">
               <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                  <div class="col-md-7 ftco-animate">
                     <span class="subheading">Emissão de NFC-e</span>
                     <h1 class="mb-4">Emita NFC-e com o Guappo NFC-e!</h1>
                     <p><a href="/#contato" class="btn btn-primary px-4 py-3 mt-3">Pedir orçamento</a></p>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="hero-wrap hero-wrap-2" style="background-image:url(/images/Fundos/4.png)">
         <div class="overlay"></div>
         <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-center">
               <div class="col-md-9 ftco-animate text-center">
                  <h1 class="mb-2 bread">Nossos Aplicativos</h1>
               </div>
            </div>
         </div>
      </section>
      <section class="ftco-section ftco-no-pb mb-5">
         <div class="container">
            <div class="row d-flex">
               <div class="col-md-12 wrap-about pr-md-4 ftco-animate">
                  <h2 class="mb-4 text-center">Conheça Alguns</h2>
                  <p class="text-center">Conosco a simplicidade e tecnologia caminham lado a lado, proporcionando a você uma melhor experiência!</p>
                  <div class="row mt-5">
                     <div class="col-lg-6">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Guappo NFC-e</h3>
                              <p>
                                 Com o Guappo NFC-e você consegue emitir notas fiscais eletrônicas via app, também consegue emitir nota de uma comanda/pedido.
                              </p>
                           </div>
                        </div>
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Guappo Contagem</h3>
                              <p>Nosso aplicativo de contagem permite que você controle o saldo das suas mercadorias sem ter que comprar nenhum equipamento a mais.</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Guappo Comanda/Pedido</h3>
                              <p>Consulte seus produtos, abra pedidos, controle suas mesas, e emita a nota fiscal integrada ao ERP e PDV.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      
      <section style="width: 99vw">
        <vueper-slides
          fade
          :visible-slides="1"
          slide-multiple
          :autoplay="true"
          :duration="3500"
          :arrows-outside="false"
        >
            <vueper-slide
              v-for="(slide, i) in slides" 
              :key="i" 
              :image="slide.image"
            />
        </vueper-slides>
      </section>
   </div>
</template>


<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
  components: { VueperSlides, VueperSlide },    
  data () {
    return {
      slides: [
        {
          image: require('../../../public/images/APPs/1.png')
        },
        {
          image: require('../../../public/images/APPs/2.png')
        },
        {
          image: require('../../../public/images/APPs/3.png')
        }
      ]
    }
  }
}
</script>