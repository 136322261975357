<style scoped>
.vueperslides--fixed-height { height: 1000px; }
</style>
<template>
   <div>
      <section class="ftco-section">
         <div class="container">
            <div class="row d-flex">
               <div class="col-md-5 order-md-last wrap-about align-items-stretch">
                  <div class="wrap-about-border">
                     <div class="img" style="background-image:url(images/Fundos/3.jpg);border"></div>
                     <div class="text">
                        <h3>A nossa missão é</h3>
                        <p>Ser um forte aliado para o nosso cliente, fornecendo produtos e serviços de altíssima qualidade.</p>
                        <p>Tornar-mos um elo de ligação entre o nosso cliente e a tecnologia.</p>
                        <p>Desenvolver produtos e serviços, que auxiliem o cliente em sua gestão, simplificando, reduzindo e aprimorando os processos, que interaja de maneira simples, segura, que possam aumentar a produtividade, reduzir custos, maximizar o tempo e que auxilie nas resoluções dos processos diários.</p>
                        <p><a href="/#contato" class="btn btn-primary py-3 px-4">Solicitar contato</a></p>
                     </div>
                  </div>
               </div>
               <div class="col-md-7 wrap-about pr-md-4 ftco-animate">
                  <h2 class="mb-4">Quem Somos</h2>
                  <p>Somos uma empresa que é pautada e comprometida com os mais elevados padrões de ética e conduta por parte de nossos colaboradores, parceiros e terceirizados.</p>
                  <p>Sob esta metodologia de pensar que nos levam em direção a tomada de decisões mais assertivas, e que permite galgar o sucesso, qualidade, satisfação e reconhecimento da nossa excelência no desenvolvimento de produtos e serviços.</p>
                  <p>O nosso objetivo é proporcionar um ambiente sereno, agradável e profissional, pois possuímos em nossa essência um conceito familiar de um significado extremamente importante para nós, “O pensamento que somos uma grande família, e, como tal, temos as alegrias e também momento difíceis”, mas com a certeza de que juntos conseguiremos aprender a superar as adversidades, tornando-nos mais fortes, unidos e experientes.</p>
                  <h2 class="mb-4">Experiência</h2>
                  <p>A TIAPPs Tecnologia, desenvolve soluções que possibilitem a maximização do tempo e que simplifiquem os processos complexos e repetitivos, acreditamos que o sentimento de realização de uma fábrica de software é a transformação de sonhos em realidade, conseguindo desta forma atender às expectativas e as demandas de mercado.</p>
                  <h2 class="mb-4">Fundação</h2>
                  <p>A TIAPPs Tecnologia, empresa jurídica de direito privado fundada em 14 de fevereiro de 2019, foi constituída com o objetivo principal de desenvolver Apps (aplicativos), diante da carência de mercado, por novos produtos, ampliamos o nosso portifólio, produzindo soluções inteligentes para os mais diversos e variados ramos de atividade.</p>
               </div>
            </div>
         </div>
      </section>
      <section class="ftco-section ftco-counter">
         <div class="container">
            <div class="row justify-content-center mb-5 pb-2 d-flex">
               <div class="col-md-6 align-items-stretch d-flex">
                  <div class="img img-video d-flex align-items-center" style="background-image: url(images/TechBrain.png);">
                  </div>
               </div>
               <div class="col-md-6 heading-section ftco-animate pl-lg-5 pt-md-0 pt-5">
                  <h2 class="mb-4">Visão e Valores</h2>
                  <p>Não podemos ser apenas uma fábrica de softwares, devemos ser uma fábrica de soluções que atendam às necessidades dos nossos clientes.</p>
                  <p>Provar de forma simples a nossa eficiência e competência, pela qualidade dos nossos produtos e serviços.</p>
                  <p>O compromisso com a qualidade de vida no trabalho (QVT);</p>
                  <p>O comprometimento, confiança, criatividade, flexibilidade, honestidade, humildade, simplicidade e ser ético, assegurando desta forma os fatores motivadores que nos levam ao sucesso; ser profissional, de forma independente e imparcial e em total conformidade com as melhores prática.</p>
               </div>
            </div>
         </div>
      </section>
      <section class="ftco-intro ftco-no-pb img" style="background-image:url(images/Fundos/2.jpg)">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-10 text-center heading-section heading-section-white ftco-animate">
                  <h2 class="mb-0">Seu negócio em primeiro lugar!</h2>
               </div>
            </div>
         </div>
      </section>
      <section class="ftco-section testimony-section">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-8 text-center heading-section ftco-animate">
                  <h2 class="mb-4">Conheça a nossa estrutura</h2>
                  <p>Separamos algumas fotos especiais da nossa empresa visando mostrar o quão bem preparados estamos para fazer o seu negócio evoluir com a gente!</p>
               </div>
            </div>
         </div>
      </section>
      <section style="width: 99vw;">
        <vueper-slides
          fade
          :visible-slides="1"
          slide-multiple
          :autoplay="true"
          :duration="3500"
          :arrows-outside="false"
          :fixed-height="true"
        >
            <vueper-slide
              v-for="(slide, i) in slides" 
              :key="i" 
              :image="slide.image"
            />
        </vueper-slides>
      </section>
   </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
  components: { VueperSlides, VueperSlide },    
  data () {
    return {
      slides: [
        {
          image: require('../assets/empresa/1.jpg')
        },
        {
          image: require('../assets/empresa/2.jpg')
        },
        {
          image: require('../assets/empresa/3.jpg')
        },
        {
          image: require('../assets/empresa/4.jpg')
        },
        {
          image: require('../assets/empresa/5.jpg')
        },
        {
          image: require('../assets/empresa/6.jpg')
        },
        {
          image: require('../assets/empresa/7.jpg')
        },
        {
          image: require('../assets/empresa/8.jpg')
        }
      ]
    }
  }
}
</script>