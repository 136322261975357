<style>
   .text-justify {
      text-align: justify;
   }
</style>
<template>
   <div>
      <section class="home-slider owl-carousel" id="inicio">
         <div class="slider-item" style="background-image:url(/images/ERP/Banner_1.png)">
            <div class="overlay"></div>
            <div class="container">
               <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                  <div class="col-md-7 ftco-animate">
                     <span class="subheading">Melhor relação Custo x Benefício.</span>
                     <h1 class="mb-4">O sistema ideal para Controle de Estoque.</h1>
                     <p><a href="/#contato" class="btn btn-primary px-4 py-3 mt-3">Pedir orçamento</a></p>
                  </div>
               </div>
            </div>
         </div>
         <div class="slider-item" style="background-image:url(/images/ERP/Banner_2.png)">
            <div class="overlay"></div>
            <div class="container">
               <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                  <div class="col-md-7 ftco-animate">
                     <span class="subheading">O seu negócio merece mais!</span>
                     <h1 class="mb-4">Visual Elegante, Simplicidade e Agilidade!</h1>
                     <p><a href="/#contato" class="btn btn-primary px-4 py-3 mt-3">Pedir orçamento</a></p>
                  </div>
               </div>
            </div>
         </div>
         <div class="slider-item" style="background-image:url(/images/ERP/Banner_3.png)">
            <div class="overlay"></div>
            <div class="container">
               <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                  <div class="col-md-7 ftco-animate">
                     <span class="subheading">Tudo o que você precisa em um só lugar!</span>
                     <h1 class="mb-4">NF-e, SPED, Controle de Saldo, e muito mais.</h1>
                     <p><a href="/#contato" class="btn btn-primary px-4 py-3 mt-3">Pedir orçamento</a></p>
                  </div>
               </div>
            </div>
         </div>
         <div class="slider-item" style="background-image:url(/images/ERP/Banner_4.png)">
            <div class="overlay"></div>
            <div class="container">
               <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                  <div class="col-md-7 ftco-animate">
                     <span class="subheading">Conheça mais sobre o Guappo ERP.</span>
                     <h1 class="mb-4">Foque no que Realmente Importa,<br> o Seu Negócio!</h1>
                     <p><a href="/#contato" class="btn btn-primary px-4 py-3 mt-3">Pedir orçamento</a></p>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="hero-wrap hero-wrap-2" style="background-image:url(/images/Fundos/4.png)">
         <div class="overlay"></div>
         <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-center">
               <div class="col-md-9 ftco-animate text-center">
                  <h1 class="mb-2 bread">Guappo ERP</h1>
               </div>
            </div>
         </div>
      </section>
      <section class="ftco-section ftco-no-pb mb-5">
         <div class="container">
            <div class="row d-flex">
               <div class="col-md-12 wrap-about pr-md-4 ftco-animate">
                  <h2 class="mb-4 text-center">Conheça algumas funcionalidades</h2>
                  <p class="text-center">Conosco a simplicidade e tecnologia caminham lado a lado, proporcionando a você uma melhor experiência!</p>
                  <div class="row mt-5">
                     <div class="col-lg-12">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">NF-e (Emissor de Nota Fiscal)</h3>
                              <p>Com a nosso emissor de NF-e, sua vida ficou mais fácil, integrado com o módulo de contas a receber o sistema gera lançamentos automáticos das movimentações financeiras dos clientes controlando o fluxo de vendas, controle de preços de venda em promoções, cálculo automático dos impostos, emissão da carta de correção para retificação da NF-e, DANFE personalizada com o logotipo da empresa, substituição tributária, não utilizamos ferramentas terceirizados no processo da NF-e, validação da NF-e antes do envio ao SEFAZ, diversos relatórios etc.</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Produtos</h3>
                              <p>É a base principal para o funcionamento das rotinas do sistema, localização física, controle de preços de venda e custos, controle de saldo unitário e embalagens, dados fiscais, controle por composição de produtos, históricos de venda etc.</p>
                           </div>
                        </div>
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Relatórios</h3>
                              <p>Os relatórios gerenciais são fundamentais em um processo de acompanhamento e planejamento para a tomada de decisão, as dificuldades que um gestor enfrenta são muitas, por este entre outros motivos a necessidade de um software que contempla vários modelos de relatórios para servir de auxílio a tomada de decisão.</p>
                              <p>O sistema possui um Gerador de Relatórios, muito interativo, fácil e prático, dando a liberdade ao usuário para criar os seus próprios relatórios.</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Importação de XML</h3>
                              <p>Ao importar o XML da nota no momento do lançamento no modulo de compras estamos reduzindo o tempo, margem de erros, vinculação inteligente entre o XML e o pedido de compras, monitoramento da validade do documento, associação do produto e fornecedor etc.</p>
                           </div>
                        </div>
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">SPED (Sistema Público de Escrituração Digital)</h3>
                              <p>O SPED é um arquivo no formato texto que detalha todas as movimentações fiscais de compra e venda da empresa por período.</p>
                              <p>Com a obtenção da informação, compilação, geração, validação e o gerenciamento dos arquivos digitais, respeitando todas as regras estabelecidas pelo Fisco, de forma confiável, resultando em arquivos digitais, validados pelo programa PVA, prontos para serem transmitidos.</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Contas à Pagar</h3>
                              <p>Projetado para antecipar e controlar as obrigações financeiras no período, possui muitos recursos que facilitam as tomadas de decisões diárias, integrado com o compras reduz o retrabalho e margem de erros etc.</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Contas à Receber</h3>
                              <p>Projetado para controlar os recebimentos no período, possui muitos recursos que facilitam as tomadas de decisões diárias, integrado com as vendas reduz o retrabalho e margem de erros etc.</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Clientes</h3>
                              <p>Saiba mais sobre o seu cliente, antecipar é vender mais, projetado para atender as rotinas diárias, muito prático, controle de limite de crédito, histórico de vendas etc.</p>
                           </div>
                        </div>
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Fornecedores</h3>
                              <p>Um forte aliado, monitore suas compras, saiba o que comprar e de quem comprar, controle os seus custos etc.</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Personalização</h3>
                              <p>
                                 Com o Guappo ERP você pode customizar todo o sistema, inclusive os PDV's com as cores do seu négocio, além de conseguir colocar também a sua <b>LOGO</b>.
                              </p>
                           </div>
                        </div>
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Controle de Usuários</h3>
                              <p>Utilizado para controlar os acessos e permissões de uso do sistema.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      
      <section style="width: 99vw">
        <vueper-slides
          fade
          :visible-slides="1"
          slide-multiple
          :autoplay="true"
          :duration="3500"
          :arrows-outside="false"
        >
            <vueper-slide
              v-for="(slide, i) in slides" 
              :key="i" 
              :image="slide.image"
            />
        </vueper-slides>
      </section>
   </div>
</template>


<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
  components: { VueperSlides, VueperSlide },    
  data () {
    return {
      slides: [
        {
          image: require('../../../public/images/ERP/1.png')
        },
        {
          image: require('../../../public/images/ERP/2.png')
        },
        {
          image: require('../../../public/images/ERP/3.png')
        },
        {
           image: require('../../../public/images/ERP/4.png')
        }
      ]
    }
  }
}
</script>