<style>
   .text-justify {
      text-align: justify;
   }
</style>
<template>
   <div>
      <section class="home-slider owl-carousel" id="inicio">
         <div class="slider-item" style="background-image:url(/images/VendaMais/5.png)">
            <div class="overlay"></div>
            <div class="container">
               <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                  <div class="col-md-7 ftco-animate">
                     <span class="subheading">Tenha uma loja online!</span>
                     <h1 class="mb-4">Para quem deseja aumentar as vendas!</h1>
                     <p><a href="/#contato" class="btn btn-primary px-4 py-3 mt-3">Pedir orçamento</a></p>
                  </div>
               </div>
            </div>
         </div>
         <div class="slider-item" style="background-image:url(/images/VendaMais/2.png)">
            <div class="overlay"></div>
            <div class="container">
               <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                  <div class="col-md-7 ftco-animate">
                     <span class="subheading">Integrado ao ERP!</span>
                     <h1 class="mb-4">Sincronize seus produtos, clientes e vendas!</h1>
                     <p><a href="/#contato" class="btn btn-primary px-4 py-3 mt-3">Pedir orçamento</a></p>
                  </div>
               </div>
            </div>
         </div>
         <div class="slider-item" style="background-image:url(/images/VendaMais/3.png)">
            <div class="overlay"></div>
            <div class="container">
               <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                  <div class="col-md-7 ftco-animate">
                     <span class="subheading">Tudo o que você precisa em um só lugar!</span>
                     <h1 class="mb-4">Abra sua loja online hoje mesmo e já comece a vender!</h1>
                     <p><a href="/#contato" class="btn btn-primary px-4 py-3 mt-3">Pedir orçamento</a></p>
                  </div>
               </div>
            </div>
         </div>
         <div class="slider-item" style="background-image:url(/images/VendaMais/4.png)">
            <div class="overlay"></div>
            <div class="container">
               <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                  <div class="col-md-7 ftco-animate">
                     <span class="subheading">Conheça mais sobre o Guappo Venda +.</span>
                     <h1 class="mb-4">Entre em contato e solicite a integração deste produto!</h1>
                     <p><a href="/#contato" class="btn btn-primary px-4 py-3 mt-3">Pedir orçamento</a></p>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="hero-wrap hero-wrap-2" style="background-image:url(/images/Fundos/4.png)">
         <div class="overlay"></div>
         <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-center">
               <div class="col-md-9 ftco-animate text-center">
                  <h1 class="mb-2 bread">Guappo Venda +</h1>
               </div>
            </div>
         </div>
      </section>
      <section class="ftco-section ftco-no-pb mb-5">
         <div class="container">
            <div class="row d-flex">
               <div class="col-md-12 wrap-about pr-md-4 ftco-animate">
                  <h2 class="mb-4 text-center">Conheça algumas funcionalidades</h2>
                  <p class="text-center">Conosco a simplicidade e tecnologia caminham lado a lado, proporcionando a você uma melhor experiência!</p>
                  <div class="row mt-5">
                     <div class="col-lg-6">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Painel de Pedidos</h3>
                              <p>
                                 Com o Guappo Venda + seus clientes conseguem pedir de onde estiverem, e além disso você recebe seus pedidos em tempo real!
                              </p>
                           </div>
                        </div>
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Controle de Zonas de Entrega</h3>
                              <p>Para quem deseja controlar taxas de entrega por regiões.</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Categorias</h3>
                              <p>Personalize sua loja criando categorias que servirão de filtros de produtos para seus clientes.</p>
                           </div>
                        </div>
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Formas de pagamento</h3>
                              <p>Crie formas de pagamento específicas e também utilize o PIX.</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Produtos</h3>
                              <p>Cadastre seus produtos, adicione fotos, descrições e muito mais!</p>
                           </div>
                        </div>
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Relatórios</h3>
                              <p>Controle suas vendas online através dos relatórios disponibilizados, além de conseguir controlar também pelo Guappo ERP.</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Controle de horários</h3>
                              <p>Controle seus horários de abertura e fechamento para que seus clientes fiquem sempre informados!</p>
                           </div>
                        </div>
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Link personalizado</h3>
                              <p>Crie seu próprio link e compartilhe nas redes sociais e onde quiser, além de conseguir apontar um domínio personalizado.</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Personalização</h3>
                              <p>Você consegue personalizar a foto de perfil, foto de capa e as cores do seu site!</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <div class="services text-justify">
                           <div class="text media-body">
                              <h3 class="text-center">Robô de auto-atendimento</h3>
                              <p>Nos planos premium e ilimitado você tem acesso ao nosso robô com inteligência artificial que faz todo o atendimento por você via WhatsApp.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      
      <section style="width: 99vw">
        <vueper-slides
          fade
          :visible-slides="1"
          slide-multiple
          :autoplay="true"
          :duration="3500"
          :arrows-outside="false"
        >
            <vueper-slide
              v-for="(slide, i) in slides" 
              :key="i" 
              :image="slide.image"
            />
        </vueper-slides>
      </section>
   </div>
</template>


<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
  components: { VueperSlides, VueperSlide },    
  data () {
    return {
      slides: [
        {
          image: require('../../../public/images/VendaMais/1.png')
        },
        {
          image: require('../../../public/images/VendaMais/2.png')
        },
        {
          image: require('../../../public/images/VendaMais/5.png')
        },
        {
          image: require('../../../public/images/VendaMais/4.png')
        }
      ]
    }
  }
}
</script>