<style>
.post {
  transition: background-color 0.5s ease-in-out;
}
.post:hover {
  background-color: #5b48e6;
}
</style>
<template>
  <div>
    <section class="ftco-section">
      <div class="container">
        <div class="row justify-content-center mb-5 pb-2">
          <div class="col-md-8 text-center heading-section ftco-animate">
            <h2>Últimas novidades do BLOG</h2>
            <p class="mb-4">
              Fique por dentro das últimas atualizações da nossa empresa.
            </p>
          </div>
        </div>
        <div class="no-gutters" v-for="(item, index) in items" :key="index" style="margin-block:10px;">
          <a @click="irPostagem(item.codigo, item.titulo)">
            <div
              class="col-lg-12 d-flex post"
              style="cursor: pointer; color: rgb(255, 255, 255); border-radius: 8px;"
            >
              <div class="services-2 noborder-left text-center">
                <div class="text media-body">
                  <h4 style="font-size: 15px; color: rgb(255, 255, 255) !important;">
                    Postado em: {{ new Date(item.createdAt).toLocaleDateString() }} {{ new Date(item.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }}
                  </h4>
                  <h3 style="font-size: 30px; color: rgb(255, 255, 255) !important;">
                   {{ item.titulo }}
                  </h3>
                  <p>
                    <b style="color: #ff9900">conferir postagem...</b>.
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "../../axios/index";
import path from "path";
export default {
  data() {
    return {
      items: [],
      photo: ''
    };
  },
  created() {
    this.pegarPost()
  },
  methods: {
    convertToURL(title) {
      // Convert title to lowercase and replace spaces with hyphens
      let url = title.toLowerCase().replace(/\s+/g, "-");
      
      // Remove special characters except hyphens
      url = url.replace(/[^a-z0-9-]/g, "");
      
      // Remove consecutive hyphens
      url = url.replace(/-+/g, "-");
      
      // Remove leading and trailing hyphens
      url = url.replace(/^-+|-+$/g, "");
      
      return url;
    },
    irPostagem(codigo, titulo) {
      const pagina = titulo.toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      this.$router.push({
        path: path.join('/blog/posts/', codigo.toString() + '-' + this.convertToURL(pagina.toString()).toLowerCase()),
        props: codigo
      })
    },
    async pegarPost() {
      await axios.get(`/blog/list/bySite/-1/-1`)
        .then(async (response) => {
          if (response.data.length > 0) {
            response.data.map(item => {
              this.items.push(item)
              console.log(this.items)
            })
          } else {
            if (this.items.length === 0) {
              this.showEmptyLabel = true
            }
          }
        }).catch(error => {
          try {
            this.$q.notify({
              message: error.response.data.error,
              color: 'red'
            })
          } catch (errr) {
            this.$q.notify({
              message: 'Falha na conexão com a nuvem.',
              color: 'red'
            })
          }
        }).finally(() => {
        })
    },
    ajustarConteudo(conteudo, limite) {
      if (conteudo.length <= limite) {
        return conteudo;
      }

      var textoSemReticencias = conteudo.substring(0, limite);
      var ultimaPalavraIndex = textoSemReticencias.lastIndexOf(" ");
      var textoModificado = textoSemReticencias.substring(0, ultimaPalavraIndex) + "...";

       const textoSemTags = textoModificado.replace(/<[^>]+>/g, '');

      return textoSemTags;
    },
  }
}
</script>
