<template>
   <div>
      <section class="ftco-section" style="padding-bottom: 0 !important;">
         <div class="container">
            <div class="row d-flex text-center">
               <div class="col-md-12 wrap-about pr-md-4 ftco-animate">
                  <h2 class="mb-4">Seja bem-vindo(a) ao nosso centro de privacidade.</h2>
                  <p>Aqui você encontrará nossas políticas, código de conduta entre outras informações.</p>
               </div>
            </div>
         </div>
      </section>
      <section class="ftco-section ftco-counter" v-if="carregouLinks">
         <div class="container">
            <div class="row justify-content-center mb-5 pb-2 d-flex">
               <h2 class="mb-4">Políticas de privacidade</h2>
               <iframe :src="links.politicaPrivacidade" width="100%" height="400px" style="max-height: calc(100vh - 155px);"> </iframe>
            </div>
            <div class="row justify-content-center mb-5 pb-2 d-flex">
               <h2 class="mb-4">Políticas de cookies</h2>
               <iframe :src="links.politicaCookie" width="100%" height="400px" style="max-height: calc(100vh - 155px);"> </iframe>
            </div>
            <div class="row justify-content-center mb-5 pb-2 d-flex">
               <h2 class="mb-4">Código de conduta</h2>
               <iframe :src="links.codigoConduta" width="100%" height="400px" style="max-height: calc(100vh - 155px);"> </iframe>
            </div>
         </div>
      </section>
   </div>
</template>

<script>
import axios from '../axios/index'
export default {
   data () {
      return {
         links: {
            politicaPrivacidade: '',
            politicaCookie: '',
            codigoConduta: '',
         }
      }
   },
   async created () {
      await axios.post('politicaslgpd/versoestermos/listar').then((response) => {
         this.links.politicaPrivacidade = response.data.linkPoliticaPrivacidade
         this.links.politicaCookie = response.data.linkPoliticaCookies
         this.links.codigoConduta = response.data.linkPoliticaConduta
      }).catch((err) => {
         console.log(err)
         alert('Não foi possível carregar os termos de privacidade, entre em contato conosco!')
      })
   },
   computed: {
      carregouLinks () {
         return this.links.politicaPrivacidade && this.links.politicaCookie && this.links.codigoConduta
      }
   }
}
</script>