<style>
.header-security {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  background-color: #ff4545;
  color: #fff;
  text-align: center;
}

.footer-lgpd {
  width: 100%;
  height: 70px;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  background-color: #444;
  position: fixed;
  bottom: 0;
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .header-security {
    width: 100%;
    height: 110px;
    display: flex;
    justify-content: center;
    background-color: #ff4545;
    color: #fff;
    text-align: center;
  }
  .footer-lgpd {
    width: 100%;
    height: 100px;
    z-index: 30000000;
    display: flex;
    justify-content: center;
    background-color: #444;
    position: fixed;
    bottom: 0;
    color: #fff;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .header-security {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    background-color: #ff4545;
    color: #fff;
    text-align: center;
  }
  .footer-lgpd {
    width: 100%;
    height: 100px;
    z-index: 300000000;
    display: flex;
    justify-content: center;
    background-color: #444;
    position: fixed;
    bottom: 0;
    color: #fff;
    text-align: center;
  }
}
</style>
<template>
  <div id="app">
    <router-view></router-view>
    <div class="footer-lgpd" v-if="!dateAcceptedTerms">
      <p
        class="text-subtitle1"
        style="align-self: center; padding: 0; margin: 0"
      >
        <span
          >Ao continuar usando este site você está de acordo com as nossas
          <a class="text-white" href="/politicas" target="_blank"
            >políticas de privacidade</a
          >.
        </span>
        <input type="button" value="Aceitar" @click="dismissLgpd" />
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      dateAcceptedTerms: null,
    };
  },
  mounted() {
    this.dateAcceptedTerms = !!localStorage.getItem("dateAcceptedTerms");
  },
  methods: {
    dismissLgpd() {
      localStorage.setItem("dateAcceptedTerms", new Date());
      this.dateAcceptedTerms = new Date();
    },
  },
};
</script>
