import Index from "./pages/index.vue";
import About from "./pages/about.vue";
import Download from "./pages/download.vue";
import LinhaGuappoERP from "./pages/linhaguappo/erp.vue";
import LinhaGuappoPDV from "./pages/linhaguappo/pdv.vue";
import LinhaGuappoApps from "./pages/linhaguappo/apps.vue";
import LinhaGuappoVendaMais from "./pages/linhaguappo/vendamais.vue";
import LGPD from "./pages/lgpd.vue";
import DefaultLayout from "./layouts/default.vue";
import LinhaGuappoLayout from "./layouts/linhaguappo.vue";
import Blog from "./pages/blog/index.vue";
import Integracoes from "./pages/integracoes/index.vue";
import Posts from "./pages/blog/posts.vue"

export default [
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "",
        component: Index,
      },
      {
        path: "quemsomos",
        component: About,
      },
      {
        path: "downloads",
        component: Download,
      },
      {
        path: "politicas",
        component: LGPD,
      },
      {
        path: "linhaguappo",
        component: LinhaGuappoLayout,
        children: [
          {
            path: "erp",
            component: LinhaGuappoERP,
          },
          {
            path: "pdv",
            component: LinhaGuappoPDV,
          },
          {
            path: "apps",
            component: LinhaGuappoApps,
          },
          {
            path: "vendamais",
            component: LinhaGuappoVendaMais,
          },
        ],
      },
      {
        path: "blog",
        component: Blog,
      },
      {
        path: "integracoes",
        component: Integracoes,
      },
      {
        path: "blog/posts/:codigo?",
        component: Posts,
        props: true
      },
    ],
  },
];
