<style>
.post {
  background-color: #312783;
  transition: background-color 0.5s ease-in-out;
}
.post:hover {
  background-color: #5b48e6;
}
.container-post {
  gap: 20px;
}

.text-contorno {
  -webkit-text-stroke: 0.5px #333;
}

@media (max-width: 575.98px) {
  .altura-mapa {
    height: 300px;
  }
  .post-index {
    max-width: 90%;
  }
  .banner-pos {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetsmobile/celular.webp);
  }
  .banner-1 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetsmobile/banner1.webp);
  }
  .banner-2 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetsmobile/banner2.webp);
  }
  .banner-3 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetsmobile/banner3.webp);
  }
  .banner-4 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetsmobile/banner4.webp);
  }
  .banner-5 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetsmobile/banner5.webp);
  }
}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
  .altura-mapa {
    height: 300px;
  }
  .post-index {
    max-width: 90%;
  }
  .banner-pos {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetsmobile/celular.webp);
  }
  .banner-1 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetsmobile/banner1.webp);
  }
  .banner-2 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetsmobile/banner2.webp);
  }
  .banner-3 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetsmobile/banner3.webp);
  }
  .banner-4 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetsmobile/banner4.webp);
  }
  .banner-5 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetsmobile/banner5.webp);
  }
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
  .altura-mapa {
    height: 400px;
  }
  .post-index {
    max-width: 90%;
  }
  .banner-pos {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetsmobile/celular.webp);
  }
  .banner-1 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetsmobile/banner1.webp);
  }
  .banner-2 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetsmobile/banner2.webp);
  }
  .banner-3 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetsmobile/banner3.webp);
  }
  .banner-4 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetsmobile/banner4.webp);
  }
  .banner-5 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetsmobile/banner5.webp);
  }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
  .altura-mapa {
    height: 400px;
  }
  .post-index {
    max-width: 32%;
  }
  .banner-pos {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetspc/desktop.webp);
  }
  .banner-1 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetspc/banner1.webp);
  }
  .banner-2 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetspc/banner2.webp);
  }
  .banner-3 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetspc/banner3.webp);
  }
  .banner-4 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetspc/banner4.webp);
  }
  .banner-5 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetspc/banner5.webp);
  }
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .altura-mapa {
    height: 600px;
  }
  .post-index {
    max-width: 32%;
  }
  .banner-pos {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetspc/desktop.webp);
  }
  .banner-1 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetspc/banner1.webp);
  }
  .banner-2 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetspc/banner2.webp);
  }
  .banner-3 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetspc/banner3.webp);
  }
  .banner-4 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetspc/banner4.webp);
  }
  .banner-5 {
    background-image: url(https://sitetiapps.blob.core.windows.net/assetspc/banner5.webp);
  }
}
</style>
<template>
  <div>
    <section class="home-slider owl-carousel" id="inicio">
      <div class="slider-item banner-pos">
        <div class="overlay"></div>
        <div class="container">
          <div
            class="row no-gutters slider-text align-items-center justify-content-start"
            data-scrollax-parent="true"
          >
            <div class="col-md-7 ftco-animate">
              <span class="subheading text-contorno">Ágil e fácil!</span>
              <h1 class="mb-4 text-contorno">
                Maquininha TEF POS integrada ao nosso PDV.
              </h1>
              <p>
                <a href="/#contato" class="btn btn-primary px-4 py-3 mt-3"
                  >Pedir orçamento</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="slider-item banner-1">
        <div class="overlay"></div>
        <div class="container">
          <div
            class="row no-gutters slider-text align-items-center justify-content-start"
            data-scrollax-parent="true"
          >
            <div class="col-md-7 ftco-animate">
              <span class="subheading text-contorno"
                >Simples e inteligente!</span
              >
              <h1 class="mb-4 text-contorno">
                Sistema ERP e PDV com Emissor Fiscal.
              </h1>
              <p>
                <a href="/#contato" class="btn btn-primary px-4 py-3 mt-3"
                  >Pedir orçamento</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="slider-item banner-3">
        <div class="overlay"></div>
        <div class="container">
          <div
            class="row no-gutters slider-text align-items-center justify-content-start"
            data-scrollax-parent="true"
          >
            <div class="col-md-7 ftco-animate">
              <span class="subheading text-contorno"
                >Integrado ao ERP, comece a usar agora mesmo.</span
              >
              <h1 class="mb-4 text-contorno">
                Contrate o Guappo Venda + e abra sua loja online!
              </h1>
              <p>
                <a href="/#contato" class="btn btn-primary px-4 py-3 mt-3"
                  >Pedir orçamento</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="slider-item banner-2">
        <div class="overlay"></div>
        <div class="container">
          <div
            class="row no-gutters slider-text align-items-center justify-content-start"
            data-scrollax-parent="true"
          >
            <div class="col-md-7 ftco-animate">
              <span class="subheading text-contorno"
                >Controle seu comércio de onde estiver.</span
              >
              <h1 class="mb-4 text-contorno">
                O verdadeiro Gerente Virtual integrado ao seu ERP.
              </h1>
              <p>
                <a href="/#contato" class="btn btn-primary px-4 py-3 mt-3"
                  >Pedir orçamento</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="slider-item banner-4">
        <div class="overlay"></div>
        <div class="container">
          <div
            class="row no-gutters slider-text align-items-center justify-content-start"
            data-scrollax-parent="true"
          >
            <div class="col-md-7 ftco-animate">
              <span class="subheading text-contorno"
                >Sua agenda muito mais simples!</span
              >
              <h1 class="mb-4 text-contorno">
                Com o TiAgenda seus clientes podem se agendar através do seu
                link.
              </h1>
              <p>
                <a href="/#contato" class="btn btn-primary px-4 py-3 mt-3"
                  >Pedir orçamento</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="slider-item"
        style="background-image: url(/images/Banner/Representante.jpg)"
      >
        <div class="overlay"></div>
        <div class="container">
          <div
            class="row no-gutters slider-text align-items-center justify-content-start"
            data-scrollax-parent="true"
          >
            <div class="col-md-7 ftco-animate">
              <span class="subheading text-contorno">Cresça com a gente!</span>
              <h1 class="mb-4 text-contorno">
                Seja nosso representante e aumente seu leque de oportunidades!
              </h1>
              <p>
                <a href="/#contato" class="btn btn-primary px-4 py-3 mt-3"
                  >Fazer contato</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="ftco-section">
      <div class="container">
        <div class="row d-flex">
          <div class="col-md-5 order-md-last wrap-about align-items-stretch">
            <div class="wrap-about-border ftco-animate">
              <div
                class="img"
                style="background-image:url(images/Missao.jpg);border"
              ></div>
              <div class="text">
                <h3>Nosso objetivo é o mesmo que o seu!</h3>
                <p>
                  Sabemos o quão difícil é obter crescimento no mundo dos
                  negócios, por isso desenvolvemos soluções inteligentes que
                  possibilitam que você dedique tempo somente ao crescimento do
                  seu negócio.
                </p>
                <p>
                  Todos nossos sistemas são integrados e desenvolvidos para
                  fazer o seu negócio crescer, conosco você pode vender online,
                  local, por aplicativo e ainda ter controle de tudo isso!
                </p>
                <p>
                  <a href="/quemsomos" class="btn btn-primary py-3 px-4"
                    >Conheça a nossa empresa</a
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-7 wrap-about pr-md-4 ftco-animate">
            <h2 class="mb-4">Bem vindo(a)</h2>
            <p>.</p>
            <div class="row mt-5">
              <div class="col-lg-6">
                <div class="services active text-center">
                  <div
                    class="icon mt-2 d-flex justify-content-center align-items-center"
                  >
                    <svg style="width: 40px; height: 40px" viewBox="0 0 24 24">
                      <path
                        fill="#fff"
                        d="M18.72,14.76C19.07,13.91 19.26,13 19.26,12C19.26,11.28 19.15,10.59 18.96,9.95C18.31,10.1 17.63,10.18 16.92,10.18C13.86,10.18 11.15,8.67 9.5,6.34C8.61,8.5 6.91,10.26 4.77,11.22C4.73,11.47 4.73,11.74 4.73,12A7.27,7.27 0 0,0 12,19.27C13.05,19.27 14.06,19.04 14.97,18.63C15.54,19.72 15.8,20.26 15.78,20.26C14.14,20.81 12.87,21.08 12,21.08C9.58,21.08 7.27,20.13 5.57,18.42C4.53,17.38 3.76,16.11 3.33,14.73H2V10.18H3.09C3.93,6.04 7.6,2.92 12,2.92C14.4,2.92 16.71,3.87 18.42,5.58C19.69,6.84 20.54,8.45 20.89,10.18H22V14.67H22V14.69L22,14.73H21.94L18.38,18L13.08,17.4V15.73H17.91L18.72,14.76M9.27,11.77C9.57,11.77 9.86,11.89 10.07,12.11C10.28,12.32 10.4,12.61 10.4,12.91C10.4,13.21 10.28,13.5 10.07,13.71C9.86,13.92 9.57,14.04 9.27,14.04C8.64,14.04 8.13,13.54 8.13,12.91C8.13,12.28 8.64,11.77 9.27,11.77M14.72,11.77C15.35,11.77 15.85,12.28 15.85,12.91C15.85,13.54 15.35,14.04 14.72,14.04C14.09,14.04 13.58,13.54 13.58,12.91A1.14,1.14 0 0,1 14.72,11.77Z"
                      />
                    </svg>
                  </div>
                  <div class="text media-body">
                    <h3>Suporte técnico</h3>
                    <p>
                      Priorizamos o seu aprendizado e treinamos do básico ao
                      avançado.
                    </p>
                  </div>
                </div>
                <div class="services text-center">
                  <div
                    class="icon mt-2 d-flex justify-content-center align-items-center"
                  >
                    <svg style="width: 40px; height: 40px" viewBox="0 0 24 24">
                      <path
                        fill="#312783"
                        d="M12,15H14C14,16.08 15.37,17 17,17C18.63,17 20,16.08 20,15C20,13.9 18.96,13.5 16.76,12.97C14.64,12.44 12,11.78 12,9C12,7.21 13.47,5.69 15.5,5.18V3H18.5V5.18C20.53,5.69 22,7.21 22,9H20C20,7.92 18.63,7 17,7C15.37,7 14,7.92 14,9C14,10.1 15.04,10.5 17.24,11.03C19.36,11.56 22,12.22 22,15C22,16.79 20.53,18.31 18.5,18.82V21H15.5V18.82C13.47,18.31 12,16.79 12,15M2,3H5.5A5.5,5.5 0 0,1 11,8.5C11,10.69 9.71,12.59 7.86,13.47L11.64,21H9.4L5.88,14H5.5L4,14V21H2V3M5.5,12A3.5,3.5 0 0,0 9,8.5A3.5,3.5 0 0,0 5.5,5H4V12H5.5Z"
                      />
                    </svg>
                  </div>
                  <div class="text media-body">
                    <h3 style="color: #312783">SAT</h3>
                    <p>
                      Temos um serviço de assistência tributária para sanar suas
                      dúvidas sobre tributação.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="services text-center">
                  <div
                    class="icon mt-2 d-flex justify-content-center align-items-center"
                  >
                    <svg style="width: 40px; height: 40px" viewBox="0 0 24 24">
                      <path
                        fill="#312783"
                        d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
                      />
                    </svg>
                  </div>
                  <div class="text media-body">
                    <h3 style="color: #312783">SRC</h3>
                    <p>
                      Nosso serviço de relacionamento com o cliente garantirá
                      que você esteja sempre satisfeito.
                    </p>
                  </div>
                </div>
                <div class="services text-center">
                  <div
                    class="icon mt-2 d-flex justify-content-center align-items-center"
                  >
                    <svg style="width: 40px; height: 40px" viewBox="0 0 24 24">
                      <path
                        fill="#312783"
                        d="M13,9H11V12H8V14H11V17H13V14H16V12H13M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22A9,9 0 0,0 21,13A9,9 0 0,0 12,4M22,5.72L17.4,1.86L16.11,3.39L20.71,7.25M7.88,3.39L6.6,1.86L2,5.71L3.29,7.24L7.88,3.39Z"
                      />
                    </svg>
                  </div>
                  <div class="text media-body">
                    <h3 style="color: #312783">Plantão Técnico</h3>
                    <p>
                      Temos plantão pós horário de expediente para caso você
                      precise de ajuda.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="ftco-intro ftco-no-pb img"
      style="background-image: url(images/Fundos/1.png)"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-md-10 text-center heading-section heading-section-white ftco-animate"
          >
            <h2 class="mb-0">Não perca mais tempo, vem com a gente!</h2>
          </div>
        </div>
      </div>
    </section>
    <section class="ftco-section">
      <div class="container">
        <div class="row justify-content-center mb-5 pb-2">
          <div class="col-md-8 text-center heading-section ftco-animate">
            <h2 class="mb-4">Alguns dos nossos serviços</h2>
            <p>
              Nossos sistemas são concebidos com todas as funcionalidades
              exclusivas para a automatização do seu negócio.
            </p>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-lg-4 d-flex">
            <div class="services-2 noborder-left text-center ftco-animate">
              <div
                class="icon mt-2 d-flex justify-content-center align-items-center"
              >
                <div class="servico-bloco">
                  <img
                    src="../assets/modulos/nfce.png"
                    loading="lazy"
                    alt="Emissão de nfc-e ficou muito mais fácil e rápida com o nosso módulo de emissão ilimitado."
                  />
                </div>
              </div>
              <div class="text media-body">
                <h2
                  style="
                    font-weight: bold !important;
                    font-size: 28px !important;
                  "
                >
                  NFC-e
                </h2>
                <p style="margin-bottom: 0">Múltiplos terminais de venda;</p>
                <p style="margin-bottom: 0">Controle de caixa (por turno);</p>
                <p style="margin-bottom: 0">Controle de balança no caixa;</p>
                <p style="margin-bottom: 0">
                  Integração com gaveteiros de dinheiro;
                </p>

                <p style="margin-bottom: 0">Controle de débito (fiado);</p>

                <p style="margin-bottom: 0">Baixa automática do estoque;</p>

                <p style="margin-bottom: 0">
                  Funciona em modo offline (contingência);
                </p>

                <p style="margin-bottom: 0">
                  Atualização automática das operações;
                </p>

                <p style="margin-bottom: 0">TEF (múltiplas formas de pgto.);</p>

                <p style="margin-bottom: 0">Integração com financeiro;</p>

                <p style="margin-bottom: 0">E muito +</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div class="services-2 text-center ftco-animate">
              <div
                class="icon mt-2 d-flex justify-content-center align-items-center"
              >
                <div class="servico-bloco">
                  <img
                    src="../assets/modulos/nfe.png"
                    loading="lazy"
                    alt="Emissão de nota fiscal eletronica com importação de xml automático e tributação inteligente"
                  />
                </div>
              </div>
              <div class="text media-body">
                <h2
                  style="
                    font-weight: bold !important;
                    font-size: 28px !important;
                  "
                >
                  NF-e
                </h2>
                <p style="margin-bottom: 0">Sem limite de emissão;</p>
                <p style="margin-bottom: 0">Envio de notas por e-mail;</p>
                <p style="margin-bottom: 0">Armazenamento do XML;</p>
                <p style="margin-bottom: 0">Envio de XML para o contador;</p>

                <p style="margin-bottom: 0">Emite com certificado A1/A3;</p>

                <p style="margin-bottom: 0">Integração com financeiro;</p>

                <p style="margin-bottom: 0">Possui vários recursos da NFC-e;</p>

                <p style="margin-bottom: 0">E muito +</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div class="services-2 text-center ftco-animate">
              <div
                class="icon mt-2 d-flex justify-content-center align-items-center"
              >
                <div class="servico-bloco">
                  <img
                    style="width: 130px; height: 100px"
                    src="../assets/modulos/comanda.svg"
                    loading="lazy"
                    alt="Seu negócio precisa de agilidade? Então use a comanda eletronica, controle de comissões de garçons, integração com o PDV e emissão de NFC-e pela própria comanda."
                  />
                </div>
              </div>
              <div class="text media-body">
                <h2
                  style="
                    font-weight: bold !important;
                    font-size: 28px !important;
                  "
                >
                  Comanda
                </h2>
                <p style="margin-bottom: 0">Comissionamento de vendedores;</p>
                <p style="margin-bottom: 0">Envio automático para cozinha;</p>
                <p style="margin-bottom: 0">
                  Emite NFC-e e NF-e a partir do pedido;
                </p>
                <p style="margin-bottom: 0">Monitor de Mesas;</p>

                <p style="margin-bottom: 0">Rateio do valor a ser pago;</p>

                <p style="margin-bottom: 0">Controle de comandas em aberto;</p>

                <p style="margin-bottom: 0">Múltiplos vendedores;</p>

                <p style="margin-bottom: 0">Diversos relatórios;</p>

                <p style="margin-bottom: 0">E muito +</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div
              class="services-2 noborder-left noborder-bottom text-center ftco-animate"
            >
              <div
                class="icon mt-2 d-flex justify-content-center align-items-center"
              >
                <div class="servico-bloco">
                  <img
                    style="width: 130px; height: 100px"
                    src="../assets/modulos/orcamento.svg"
                    loading="lazy"
                    alt="Controle seus orçamentos de forma fácil através do guappo ERP."
                  />
                </div>
              </div>
              <div class="text media-body">
                <h2
                  style="
                    font-weight: bold !important;
                    font-size: 28px !important;
                  "
                >
                  Pedido/Orçamentos
                </h2>
                <p style="margin-bottom: 0">
                  Múltiplos terminais de atendimento;
                </p>
                <p style="margin-bottom: 0">
                  Emite NFC-e e NF-e a partir do pedido;
                </p>
                <p style="margin-bottom: 0">Controle de pedidos/orçamentos;</p>
                <p style="margin-bottom: 0">Reserva de estoque;</p>

                <p style="margin-bottom: 0">Diversos modelos de relatórios;</p>

                <p style="margin-bottom: 0">E muito +</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div class="services-2 text-center noborder-bottom ftco-animate">
              <div
                class="icon mt-2 d-flex justify-content-center align-items-center"
              >
                <div class="servico-bloco">
                  <img
                    src="../assets/modulos/sped.png"
                    loading="lazy"
                    alt="Tributação sempre foi algo difícil não é mesmo? Mas com o SPED integrado ao ERP ficou muito mais fácil, pois além de possuirmos uma equipe especializada em tributação, o sistema trata os tributos automaticamente."
                  />
                </div>
              </div>
              <div class="text media-body">
                <h2
                  style="
                    font-weight: bold !important;
                    font-size: 28px !important;
                  "
                >
                  SPED
                </h2>
                <p style="margin-bottom: 0">
                  Com o nosso gerador de arquivo SPED você não perde o prazo de
                  entrega;
                </p>
                <p style="margin-bottom: 0">
                  Contamos com equipe especializada para auxiliá-lo;
                </p>
                <p style="margin-bottom: 0">
                  Geração de arquivo simples e descomplicada;
                </p>

                <p style="margin-bottom: 0">E muito +</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div class="services-2 text-center noborder-bottom ftco-animate">
              <div
                class="icon mt-2 d-flex justify-content-center align-items-center"
              >
                <div class="servico-bloco">
                  <img
                    style="width: 130px; height: 100px"
                    src="../assets/modulos/coletor.svg"
                    loading="lazy"
                    alt="Precisa contar o saldo das mercadorias mas não quer gastar dinheiro? Não tem problema, com o guappo Contagem você consegue contar o saldo das suas mercadorias via aplicativo."
                  />
                </div>
              </div>
              <div class="text media-body">
                <h2
                  style="
                    font-weight: bold !important;
                    font-size: 28px !important;
                  "
                >
                  Coletor de Dados
                </h2>
                <p style="margin-bottom: 0">
                  Com o nosso aplicativo a coleta de dados ficou mais rápida,
                  fácil e com baixo custo;
                </p>
                <p style="margin-bottom: 0">
                  Atualização automática do saldo no estoque;
                </p>
                <p style="margin-bottom: 0">Integramos com outros softwares;</p>

                <p style="margin-bottom: 0">E muito +</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div class="services-2 text-center noborder-bottom ftco-animate">
              <div
                class="icon mt-2 d-flex justify-content-center align-items-center"
              >
                <div class="servico-bloco">
                  <img
                    style="width: 130px; height: 100px"
                    src="../assets/modulos/erp.svg"
                    loading="lazy"
                    alt="Sistema ERP com controle de estoque, relatórios, gráficos, personalização, emissão de nota fiscal, sped, e muito mais."
                  />
                </div>
              </div>
              <div class="text media-body">
                <h2
                  style="
                    font-weight: bold !important;
                    font-size: 28px !important;
                  "
                >
                  ERP
                </h2>
                <p style="margin-bottom: 0">Controle de estoque;</p>
                <p style="margin-bottom: 0">Gestão de compra e venda;</p>
                <p style="margin-bottom: 0">Múltiplos preços de venda;</p>
                <p style="margin-bottom: 0">Contas a receber e a pagar;</p>

                <p style="margin-bottom: 0">Produtos por composição;</p>

                <p style="margin-bottom: 0">Tributação inteligente;</p>

                <p style="margin-bottom: 0">Importação de NF-e;</p>

                <p style="margin-bottom: 0">Monitor de saldo;</p>

                <p style="margin-bottom: 0">Integração com balanças;</p>

                <p style="margin-bottom: 0">Boleto bancário;</p>

                <p style="margin-bottom: 0">Controle de convênios;</p>

                <p style="margin-bottom: 0">Controle de promoções;</p>

                <p style="margin-bottom: 0">Relatórios e estatísticas;</p>

                <p style="margin-bottom: 0">Gerador de relatórios;</p>

                <p style="margin-bottom: 0">E muito +</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div class="services-2 text-center noborder-bottom ftco-animate">
              <div
                class="icon mt-2 d-flex justify-content-center align-items-center"
              >
                <div class="servico-bloco">
                  <img
                    style="width: 130px; height: 100px"
                    src="../assets/modulos/apps.svg"
                    loading="lazy"
                    alt="Conheça nossos aplicativos que são capazes de agilizar o seu negócio, e o melhor, com baixo custo!"
                  />
                </div>
              </div>
              <div class="text media-body">
                <h2
                  style="
                    font-weight: bold !important;
                    font-size: 28px !important;
                  "
                >
                  Aplicativos
                </h2>
                <p style="margin-bottom: 0">Aplicativo de NFC-e;</p>
                <p style="margin-bottom: 0">Aplicativo de comanda;</p>
                <p style="margin-bottom: 0">Aplicativo coleta de dados;</p>
                <p style="margin-bottom: 0">Aplicativo de ordem de serviço;</p>

                <p style="margin-bottom: 0">Aplicativo de delivery;</p>

                <p style="margin-bottom: 0">E muito +</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="ftco-intro ftco-no-pb img"
      style="background-image: url(images/Fundos/2.jpg)"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-lg-9 col-md-8 d-flex align-items-center heading-section heading-section-white ftco-animate"
          >
            <h2 class="mb-3 mb-md-0">
              Quer conhecer melhor? Solicite Contato!
            </h2>
          </div>
          <div class="col-lg-3 col-md-4 ftco-animate">
            <p class="mb-0">
              <a href="/#contato" class="btn btn-white py-3 px-4"
                >Solicitar Contato!</a
              >
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="ftco-section ftco-no-pb" id="linhaguappo">
      <div class="container-fluid px-0">
        <div class="row no-gutters justify-content-center mb-5">
          <div class="col-md-7 text-center heading-section ftco-animate">
            <h2 class="mb-4">
              Bem vindo(a) à <b style="color: #ff9900">Linha Guappo</b>
            </h2>
            <p>
              Conheça cada detalhe dos nossos sistemas e aplicativos clicando
              nos blocos abaixo!
            </p>
            <p></p>
          </div>
        </div>
        <div class="row no-gutters">
          <div
            class="col-md-4"
            style="cursor: pointer"
            @click="open('/linhaguappo/erp')"
          >
            <div
              class="project img ftco-animate d-flex justify-content-center align-items-center"
              style="
                background-image: url(https://sitetiapps.blob.core.windows.net/assetspc/banner5.webp);
              "
            >
              <div class="overlay"></div>
              <a
                href="/linhaguappo/erp"
                class="btn-site d-flex align-items-center justify-content-center"
                ><span class="icon-subdirectory_arrow_right"></span
              ></a>
              <div class="text text-center p-4">
                <h3><a href="/linhaguappo/erp">Guappo ERP</a></h3>
              </div>
            </div>
          </div>
          <div
            class="col-md-4"
            style="cursor: pointer"
            @click="open('/linhaguappo/pdv')"
          >
            <div
              class="project img ftco-animate d-flex justify-content-center align-items-center"
              style="
                background-image: url(https://sitetiapps.blob.core.windows.net/assetspc/banner6.webp);
              "
            >
              <div class="overlay"></div>
              <a
                href="/linhaguappo/pdv"
                class="btn-site d-flex align-items-center justify-content-center"
                ><span class="icon-subdirectory_arrow_right"></span
              ></a>
              <div class="text text-center p-4">
                <h3><a href="/linhaguappo/pdv">Guappo PDV</a></h3>
              </div>
            </div>
          </div>
          <div
            class="col-md-4"
            style="cursor: pointer"
            @click="open('/linhaguappo/gerentevirtual')"
          >
            <div
              class="project img ftco-animate d-flex justify-content-center align-items-center"
              style="
                background-image: url(https://sitetiapps.blob.core.windows.net/assetspc/banner7.webp);
              "
            >
              <div class="overlay"></div>
              <a
                href="/linhaguappo/gerentevirtual"
                class="btn-site d-flex align-items-center justify-content-center"
                ><span class="icon-subdirectory_arrow_right"></span
              ></a>
              <div class="text text-center p-4">
                <h3>
                  <a href="/linhaguappo/gerentevirtual"
                    >Guappo Gerente Virtual</a
                  >
                </h3>
              </div>
            </div>
          </div>
          <div
            class="col-md-4"
            style="cursor: pointer"
            @click="open('/linhaguappo/vendamais')"
          >
            <div
              class="project img ftco-animate d-flex justify-content-center align-items-center"
              style="
                background-image: url(https://sitetiapps.blob.core.windows.net/assetspc/banner8.webp);
              "
            >
              <div class="overlay"></div>
              <a
                href="/linhaguappo/vendamais"
                class="btn-site d-flex align-items-center justify-content-center"
                ><span class="icon-subdirectory_arrow_right"></span
              ></a>
              <div class="text text-center p-4">
                <h3><a href="/linhaguappo/vendamais">Guappo Venda +</a></h3>
              </div>
            </div>
          </div>
          <div
            class="col-md-4"
            style="cursor: pointer"
            @click="open('/linhaguappo/tiagenda')"
          >
            <div
              class="project img ftco-animate d-flex justify-content-center align-items-center"
              style="
                background-image: url(https://sitetiapps.blob.core.windows.net/assetspc/banner9.webp);
              "
            >
              <div class="overlay"></div>
              <a
                href="/linhaguappo/tiagenda"
                class="btn-site d-flex align-items-center justify-content-center"
                ><span class="icon-subdirectory_arrow_right"></span
              ></a>
              <div class="text text-center p-4">
                <h3><a href="/linhaguappo/tiagenda">TiAgenda</a></h3>
              </div>
            </div>
          </div>
          <div
            class="col-md-4"
            style="cursor: pointer"
            @click="open('/linhaguappo/apps')"
          >
            <div
              class="project img ftco-animate d-flex justify-content-center align-items-center"
              style="background-image: url(images/Banner/Apps.jpg)"
            >
              <div class="overlay"></div>
              <a
                href="/linhaguappo/apps"
                class="btn-site d-flex align-items-center justify-content-center"
                ><span class="icon-subdirectory_arrow_right"></span
              ></a>
              <div class="text text-center p-4">
                <h3><a href="/linhaguappo/apps">Apps</a></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="contato"
      class="ftco-section ftco-consult ftco-no-pt ftco-no-pb"
      style="background-image: url(images/Fundos/3.jpg)"
      data-stellar-background-ratio="0.5"
    >
      <div class="overlay"></div>
      <div class="container">
        <div class="row justify-content-end">
          <div class="col-md-6 py-5 px-md-5">
            <div class="py-md-5">
              <div
                class="heading-section heading-section-white ftco-animate mb-5"
              >
                <h2 class="mb-4">Solicitar contato</h2>
                <p>
                  Venha conhecer melhor a nossa empresa e os nossos serviços,
                  preencha o formulário abaixo.
                </p>
              </div>
              <form action="index.html#" class="appointment-form ftco-animate">
                <div class="d-md-flex">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Seu nome"
                      v-model="model.nome"
                    />
                  </div>
                  <div class="form-group ml-md-4">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="E-mail para contato"
                      v-model="model.email"
                    />
                  </div>
                </div>
                <div class="d-md-flex">
                  <div class="form-group">
                    <div class="form-field">
                      <div class="select-wrap">
                        <div class="icon">
                          <span class="ion-ios-arrow-down"></span>
                        </div>
                        <select class="form-control" v-model="model.sobre">
                          <option value="">Selecione o motivo</option>
                          <option value="Produto Especifico">
                            Produto específico
                          </option>
                          <option value="Representação">Representação</option>
                          <option value="Conhecer melhor a empresa">
                            Conhecer melhor a empresa
                          </option>
                          <option value="Contratação">Contratação</option>
                          <option value="Outros">Outros</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-group ml-md-4">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Telefone para contato"
                      v-model="model.telefone"
                    />
                  </div>
                </div>
                <div class="d-md-flex">
                  <div class="form-group">
                    <textarea
                      cols="30"
                      rows="2"
                      class="form-control"
                      placeholder="Mensagem"
                      v-model="model.mensagem"
                    ></textarea>
                  </div>
                </div>
                <div class="d-md-flex">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="`Quanto é ${this.firstNumber} + ${this.secondNumber}?`"
                      v-model="rersultString"
                    />
                  </div>
                  <div class="form-group ml-md-4">
                    <input
                      value="Enviar formulário"
                      type="button"
                      class="btn btn-white py-3 px-4"
                      @click="sendContact"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="ftco-section" style="padding: 6em 0 !important">
      <div class="container">
        <div class="row justify-content-center mb-5 pb-2">
          <div class="col-md-8 text-center heading-section ftco-animate">
            <h2 class="mb-4">Conheça algumas integrações</h2>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-lg-4 d-flex">
            <div
              class="services-2 text-center ftco-animate"
              style="
                justify-content: center;
                align-items: center;
                display: flex;
                border: none !important;
              "
            >
              <div class="text media-body">
                <img
                  style="cursor: pointer"
                  @click="goTo('https://www.stone.com.br/')"
                  src="https://sistemainterno.blob.core.windows.net/integracoes/stone.png"
                  width="250px"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div
              class="services-2 text-center ftco-animate"
              style="
                justify-content: center;
                align-items: center;
                display: flex;
                border: none !important;
              "
            >
              <div class="text media-body">
                <img
                  style="cursor: pointer"
                  @click="goTo('https://sejavero.com.br/')"
                  src="https://sistemainterno.blob.core.windows.net/integracoes/vero.png"
                  width="250px"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div
              class="services-2 text-center ftco-animate"
              style="
                justify-content: center;
                align-items: center;
                display: flex;
                border: none !important;
              "
            >
              <div class="text media-body">
                <img
                  style="cursor: pointer"
                  @click="
                    goTo(
                      'https://www.softwareexpress.com.br/pt/solucoes/SiTef/'
                    )
                  "
                  src="https://sistemainterno.blob.core.windows.net/integracoes/sitef.png"
                  width="250px"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div
              class="services-2 text-center ftco-animate"
              style="
                justify-content: center;
                align-items: center;
                display: flex;
                border: none !important;
              "
            >
              <div class="text media-body">
                <img
                  style="cursor: pointer"
                  @click="goTo('https://paygo.com.br/')"
                  src="https://sistemainterno.blob.core.windows.net/integracoes/paygo.png"
                  width="250px"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div
              class="services-2 text-center ftco-animate"
              style="
                justify-content: center;
                align-items: center;
                display: flex;
                border: none !important;
              "
            >
              <div class="text media-body">
                <img
                  style="cursor: pointer"
                  @click="goTo('https://www.sicredi.com.br/home/')"
                  src="https://sistemainterno.blob.core.windows.net/integracoes/sicredi.jpg"
                  width="250px"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div
              class="services-2 text-center ftco-animate"
              style="
                justify-content: center;
                align-items: center;
                display: flex;
                border: none !important;
              "
            >
              <div class="text media-body">
                <img
                  style="cursor: pointer"
                  @click="goTo('https://advintegra.com.br/')"
                  src="https://sistemainterno.blob.core.windows.net/integracoes/adv.png"
                  width="250px"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div
              class="services-2 text-center ftco-animate"
              style="
                justify-content: center;
                align-items: center;
                display: flex;
                border: none !important;
              "
            >
              <div class="text media-body">
                <img
                  style="cursor: pointer"
                  @click="goTo('https://brasiltributario.com.br/')"
                  src="https://sistemainterno.blob.core.windows.net/integracoes/brasiltributario.png"
                  width="250px"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div
              class="services-2 text-center ftco-animate"
              style="
                justify-content: center;
                align-items: center;
                display: flex;
                border: none !important;
              "
            >
              <div class="text media-body">
                <img
                  style="cursor: pointer"
                  @click="goTo('https://grupoimendes.com.br/')"
                  src="https://sistemainterno.blob.core.windows.net/integracoes/imendes.png"
                  width="250px"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div
              class="services-2 text-center ftco-animate"
              style="
                justify-content: center;
                align-items: center;
                display: flex;
                border: none !important;
              "
            >
              <div class="text media-body">
                <img
                  style="cursor: pointer"
                  @click="goTo('https://www.mixfiscal.com.br/')"
                  src="https://sistemainterno.blob.core.windows.net/integracoes/mixfiscal.png"
                  width="250px"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div
              class="services-2 text-center ftco-animate"
              style="
                justify-content: center;
                align-items: center;
                display: flex;
                border: none !important;
              "
            >
              <div class="text media-body">
                <img
                  src="https://sistemainterno.blob.core.windows.net/integracoes/nfce.png"
                  width="180px"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div
              class="services-2 text-center ftco-animate"
              style="
                justify-content: center;
                align-items: center;
                display: flex;
                border: none !important;
              "
            >
              <div class="text media-body">
                <img
                  src="https://sistemainterno.blob.core.windows.net/integracoes/nfe.png"
                  width="150px"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div
              class="services-2 text-center ftco-animate"
              style="
                justify-content: center;
                align-items: center;
                display: flex;
                border: none !important;
              "
            >
              <div class="text media-body">
                <img
                  src="https://sistemainterno.blob.core.windows.net/integracoes/sped.jpg"
                  width="220px"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div
              class="services-2 text-center ftco-animate"
              style="
                justify-content: center;
                align-items: center;
                display: flex;
                border: none !important;
              "
            >
              <div class="text media-body">
                <img
                  src="https://sistemainterno.blob.core.windows.net/integracoes/sintegra.png"
                  width="250px"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div
              class="services-2 text-center ftco-animate"
              style="
                justify-content: center;
                align-items: center;
                display: flex;
                border: none !important;
              "
            >
              <div class="text media-body">
                <img
                  style="cursor: pointer"
                  @click="goTo('https://www.ifood.com.br/')"
                  src="https://sistemainterno.blob.core.windows.net/integracoes/ifood.png"
                  width="200px"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <div
              class="services-2 text-center ftco-animate"
              style="
                justify-content: center;
                align-items: center;
                display: flex;
                border: none !important;
              "
            >
              <div class="text media-body">
                <img
                  style="cursor: pointer"
                  @click="goTo('https://scanntech.com.br/')"
                  src="https://sistemainterno.blob.core.windows.net/integracoes/scanntech.png"
                  width="250px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <hr />
    <section class="ftco-section">
      <div class="container">
        <div class="row justify-content-center mb-5 pb-2">
          <div class="col-md-8 text-center heading-section ftco-animate">
            <h2 class="mb-4">
              Confira nosso <b><i>BLOG</i></b>
            </h2>
            <p>
              Todas atualizações de softwares, tributárias, e informações sobre
              a empresa são colocadas aqui!
            </p>
            <a href="https://tiapps.com.br/blog/">
              <b style="color: #ff9900">ver outras postagens</b>.
            </a>
          </div>
        </div>
        <div class="row" style="justify-content: center">
          <div
            class="row container-post post-index"
            style="
              justify-content: center;
              margin-inline: 5px;
              margin-block: 5px;
            "
            v-for="(item, index) in items"
            :key="index"
          >
            <div
              class="post"
              style="
                cursor: pointer;
                justify-content: center;
                border-radius: 16px;
              "
            >
              <a @click="irPostagem(item.codigo, item.titulo)">
                <div class="services-2 noborder-left text-center">
                  <div class="text media-body">
                    <h4 style="font-size: 15px; color: white !important">
                      Postado em:
                      {{ new Date(item.createdAt).toLocaleDateString() }}
                      {{
                        new Date(item.createdAt).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      }}
                    </h4>
                    <h3 style="font-size: 20px; color: white !important">
                      {{ item.titulo }}
                    </h3>
                    <p style="color: white !important">
                      {{ ajustarConteudo(item.conteudo, 30) }}
                      <b style="color: #ff9900">veja mais aqui</b>.
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
import axios from "../axios/index";
import path from "path";
export default {
  data() {
    return {
      items: [],
      sendingForm: false,
      model: {
        email: "",
        nome: "",
        telefone: "",
        sobre: "",
        mensagem: "",
        plataformaContato: 3,
      },
      firstNumber: 0,
      secondNumber: 0,
      result: 0,
      rersultString: "",
    };
  },
  created() {
    this.setValues();
    this.pegarPost();
  },
  methods: {
    convertToURL(title) {
      // Convert title to lowercase and replace spaces with hyphens
      let url = title.toLowerCase().replace(/\s+/g, "-");

      // Remove special characters except hyphens
      url = url.replace(/[^a-z0-9-]/g, "");

      // Remove consecutive hyphens
      url = url.replace(/-+/g, "-");

      // Remove leading and trailing hyphens
      url = url.replace(/^-+|-+$/g, "");

      return url;
    },
    goTo(link) {
      window.open(link, "_blank");
    },
    irPostagem(codigo, titulo) {
      const pagina = titulo
        .toString()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      this.$router.push({
        path: path.join(
          "/blog/posts/",
          codigo.toString() +
            "-" +
            this.convertToURL(pagina.toString()).toLowerCase()
        ),
        props: codigo,
      });
    },
    async pegarPost() {
      await axios
        .get(`/blog/list/bySite/-1/3`)
        .then(async (response) => {
          if (response.data.length > 0) {
            response.data.map((item) => {
              this.items.push(item);
            });
          } else {
            if (this.items.length === 0) {
              this.showEmptyLabel = true;
            }
          }
        })
        .catch((error) => {
          try {
            this.$q.notify({
              message: error.response.data.error,
              color: "red",
            });
          } catch (errr) {
            this.$q.notify({
              message: "Falha na conexão com a nuvem.",
              color: "red",
            });
          }
        })
        .finally(() => {});
    },
    ajustarConteudo(conteudo, limite) {
      if (conteudo.length <= limite) {
        return conteudo;
      }

      var textoSemReticencias = conteudo
        .replace(/<[^>]+>/g, "")
        .substring(0, limite);
      var ultimaPalavraIndex = textoSemReticencias.lastIndexOf(" ");
      var textoModificado =
        textoSemReticencias.substring(0, ultimaPalavraIndex) + "...";
      const textoSemTags = textoModificado;

      return textoSemTags;
    },
    setValues() {
      this.firstNumber = Math.floor(Math.random() * 10);
      this.secondNumber = Math.floor(Math.random() * 10);
      this.result = this.firstNumber + this.secondNumber;
      if (this.result > 10) {
        this.setValues();
      } else {
        this.$forceUpdate();
        return this.result;
      }
    },
    open(link) {
      window.location.href = link;
    },
    sendContact() {
      if (this.result === parseInt(this.rersultString)) {
        if (!this.sendingForm) {
          if (
            this.model.email &&
            this.model.nome &&
            this.model.telefone &&
            this.model.sobre &&
            this.model.mensagem
          ) {
            this.sendingForm = true;
            axios
              .post("leads/bySite/", this.model)
              .then(() => {
                this.model = {
                  email: "",
                  nome: "",
                  telefone: "",
                  sobre: "",
                  mensagem: "",
                  plataformaContato: 3,
                };
                // eslint-disable-next-line no-undef
                gtag_report_conversion(window.location.href);
                this.rersultString = "";
                this.setValues();
                alert("Formulário de contato enviado com sucesso!");
              })
              .catch((err) => {
                console.log(err);
                this.rersultString = "";
                this.setValues();
                alert(
                  "Não foi possível enviar o formulário de contato, tente novamente ou mande um e-mail!"
                );
              })
              .finally(() => {
                this.sendingForm = false;
              });
          } else {
            this.rersultString = "";
            this.setValues();
            alert("Todos os campos devem ser preenchidos!");
          }
        } else {
          this.rersultString = "";
          this.setValues();
          alert("Um formulário já está sendo enviado, aguarde!");
        }
      } else {
        this.rersultString = "";
        this.setValues();
        alert("Informe o resultado correto da conta!");
      }
    },
  },
};
</script>
